import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, iif, map, of, switchMap, tap } from 'rxjs';
import { INotificationSetting } from 'src/app/model/interfaces/notification.interface';
import { AccountService } from 'src/app/services/account/account.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { UserService } from 'src/app/services/user/user.service';
import { ExternalAdminService } from 'src/app/services/external-admin/external-admin.service';
import { Router } from '@angular/router';
import { MailtasticAuthorizationDetails } from 'src/app/model/enums/mailtastic-authorization-details.enum';
import { PaymentService } from '@services/payment/payment.service';
import { NavigationSidebarService } from '@services/navigation-sidebar/navigation-sidebar.service';
import { UtilService } from '@services/util/util.service';
import { SimpleAuthService } from '@modules/auth/services/simple-auth.service';
import { CustomerEmails, CustomerLogin } from '@services/user/user-service.interface';
import { GetStartedGuideService } from '@services/get-started-guide/get-started-guide.service';

@Injectable({
  providedIn: 'root'
})
export class TopInfoBannerService {
  /**
   * Loads the externalAdmin data
   */
  private _loadExternalAdmins$ = new BehaviorSubject<void>(undefined);
  loadExternalAdmins$ = this._loadExternalAdmins$.asObservable();

  loadExteralAdminAccounts$ = this.loadExternalAdmins$.pipe(
    switchMap(() =>
      combineLatest([of(this.accountService.getUserAccountData()), this.externalAdminService.getAccounts()])
    ),
    map(([accountData, adminAccounts]) => ({
      accountData,
      adminAccounts
    }))
  );

  /**
   * Search email text to loads the customers list based on search value
   */
  private _searchEmailText$ = new BehaviorSubject('');
  searchEmailText$ = this._searchEmailText$.asObservable();

  /**
   * Loads the all customer
   */
  loadAllCustomers$ = this.searchEmailText$.pipe(
    switchMap(searchValue =>
      iif(() => !!searchValue, this.userService.getAllCustomers(searchValue), of([] as CustomerEmails[]))
    )
  );

  /**
   * Triggers the top email notification settings update from top info banner
   */
  private _setupMode = new BehaviorSubject<string>('');
  setupMode$ = this._setupMode.asObservable();

  /**
   * Observer used when gets the user account data
   */
  getEmailNotificationData$ = this.setupMode$.pipe(
    switchMap(setupMode => combineLatest([of(setupMode), this.userService.getAccountData()])),
    map(([setupMode, userAccountData]) => ({
      notificationLanguage: userAccountData.notificationLanguage,
      setting: setupMode ? setupMode : userAccountData.mailPolicy || 'enable',
      forwardTo: userAccountData.forwardMailsTo,
      showForwardToMessage: userAccountData.mailPolicy !== 'disable'
    }))
  );

  constructor(
    private accountService: AccountService,
    private alert: AlertService,
    private authService: AuthService,
    private externalAdminService: ExternalAdminService,
    private guide: GetStartedGuideService,
    private navigationSidebarService: NavigationSidebarService,
    private paymentService: PaymentService,
    private router: Router,
    private simpleAuthService: SimpleAuthService,
    private userService: UserService,
    private utilService: UtilService
  ) {}

  /**
   * Updates the external admin account
   * @param id - The account id
   */
  changeAccount(id: string): void {
    this.utilService.loadingStart();
    this.externalAdminService
      .changeAccount(id)
      .pipe(
        tap(result => {
          this.authService.setKeyInLocalstorage(MailtasticAuthorizationDetails.companyName, result.companyName);
          this.authService.setKeyInLocalstorage(MailtasticAuthorizationDetails.userId, result?.userId || '');
          this.authService.setToken(result.token);
          this.accountService.setUserAccountData(
            result.companyName,
            result.firstname,
            result.lastname,
            result.email,
            result.adminId,
            '',
            id
          );

          // Pendo `isReady` function will not work when Adblockers extension will activated
          if (typeof pendo.isReady === 'function' && !pendo.isReady()) {
            pendo.updateOptions({
              visitor: {
                id: result.email
              },
              account: {
                id: result.companyName
              }
            });
          }
        }),
        switchMap(() => this.paymentService.getCustomerSubscriptionData(id))
      )
      .subscribe(() => {
        this.navigationSidebarService.refreshUserHasSubscription();
        this.utilService.loadingStop();
        this.loadExternalAdmin();
        void this.router.navigate(['/dashboard']);
      });
  }

  /**
   * Login into the customer account by super admin
   * @param loginData - The login data
   * @param recentLoginEmails - The recent login emails of customer
   */
  customerLogin(loginData: CustomerLogin, recentLoginEmails: string[]): void {
    if (loginData.isSuperAdminLogin) {
      this.setSuperAdminLogin();
      this.refreshAccountData();
    } else {
      this.simpleAuthService.login(loginData.customerEmail, '', true).subscribe(() => {
        this.authService.updateRecentCustomerEmail(loginData.customerEmail, recentLoginEmails);
        this.refreshAccountData();
      });
    }
  }

  /**
   * Set super admin as logged in user
   */
  setSuperAdminLogin(): void {
    const superAdminAccoutData = this.accountService.getSuperAdminAccountData();
    this.authService.setKeyInLocalstorage(MailtasticAuthorizationDetails.userId, superAdminAccoutData?.userId || '');
    this.accountService.setUserAccountData(
      superAdminAccoutData.companyName || '',
      superAdminAccoutData.firstName || '',
      superAdminAccoutData.lastName || '',
      superAdminAccoutData.email || ''
    );
    this.authService.setToken(this.authService.getSuperAdminToken());
  }

  /**
   * Refresh the login data
   */
  refreshAccountData(): void {
    this.guide.triggerGuideData();
    this.navigationSidebarService.refreshUserHasSubscription();
    this.navigationSidebarService.setSubMenuIsOpened(false);
    this.loadExternalAdmin();
    void this.router.navigate(['/dashboard']);
  }

  /**
   * Logout from the application
   */
  doLogout(): void {
    this.userService.logout();
  }

  /**
   * Changes from email notification settings
   * @param setupMode - The mode of settings
   */
  onEmailNotificationSettingUpdate(setupMode: string): void {
    this._setupMode.next(setupMode);
  }

  /**
   * Refresh search value
   * @param searchValue - The filtered text
   */
  refreshSearchValue(searchValue: string): void {
    this._searchEmailText$.next(searchValue);
  }

  /**
   * Sets new notification settings for the account
   * @param newSettings - New settings to set
   */
  updateEmailNotificationSettings(newSettings: INotificationSetting): void {
    this.userService.setEmailNotificationSetting(newSettings).subscribe(() => {
      void this.alert.defaultSuccessMessage(this.alert.translateChangesSaved());
      if (newSettings.setting) {
        this.onEmailNotificationSettingUpdate(newSettings.setting);
      }
    });
  }

  /**
   * Load external admin for top bar menu
   */
  loadExternalAdmin(): void {
    this._loadExternalAdmins$.next();
  }
}
